import React, { useState, useEffect, useRef } from "react"
import { Form, Button, Row, Col, Input, FormGroup, Label, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_delete, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/projects"
import { css } from "glamor"
import { PropagateLoader } from "react-spinners"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import UserPicker from "../../../components/support/input-search/remove-access-picker"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const Remove = () => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [role, setRole] = useState("")
  const [whitelist, setWhitelist] = useState([])
  const [selectUsers, setSelectUsers] = useState([])
  const [admins, setAdmins] = useState([])
  const [controllers, setControllers] = useState([])
  const [maintainers, setMaintainers] = useState([])
  const [developers, setDevelopers] = useState([])
  const [reporters, setReporters] = useState([])
  const [editors, setEditors] = useState([])
  const [ticketLink, setTicketLink] = useState("")
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectProject) {
      if (selectProject.status !== "active") {
        setButtonTooltip("The specified project is not active.")
      } else {
        setButtonTooltip("")
      }
      setModalLoading(true)
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            index
              ? `projects/${selectProject.projectSlug}?index=${index}`
              : `projects/${selectProject.projectSlug}?parentOrganization=${parentOrganization}`,
            accessToken
          )
        )
        .then(response => {
          setModalLoading(false)
          if (response.roles.hasOwnProperty("admins")) {
            setAdmins(response.roles.admins)
          } else {
            setAdmins([])
          }
          if (response.roles.hasOwnProperty("controllers")) {
            setControllers(response.roles.controllers)
          } else {
            setControllers([])
          }
          if (response.roles.hasOwnProperty("maintainers")) {
            setMaintainers(response.roles.maintainers)
          } else {
            setMaintainers([])
          }
          if (response.roles.hasOwnProperty("developers")) {
            setDevelopers(response.roles.developers)
          } else {
            setDevelopers([])
          }
          if (response.roles.hasOwnProperty("reporters")) {
            setReporters(response.roles.reporters)
          } else {
            setReporters([])
          }
          if (response.roles.hasOwnProperty("editors")) {
            setEditors(response.roles.editors)
          } else {
            setEditors([])
          }
        })
        .catch(error => {
          setModalLoading(false)
          Sentry.captureException(error)
          console.log(`Error while getting project details: ${error}`)
        })
    }
    setRole("admin")
  }, [selectProject]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let data = []
    if (role === "admin") {
      data = admins
    } else if (role === "controller") {
      data = controllers
    } else if (role === "maintainer") {
      data = maintainers
    } else if (role === "developer") {
      data = developers
    } else if (role === "reporter") {
      data = reporters
    } else if (role === "editor") {
      data = editors
    }
    const userList = data.map(u => ({
      displayName: u.displayName,
      username: u.username,
      email: u.email,
    }))
    setWhitelist(userList)
    setSelectUsers([])
  }, [selectProject, role, admins, controllers, maintainers, developers, reporters, editors]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    let patches = { role, userNames: selectUsers.map(u => u.username) }
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_delete(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/role?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/role?parentOrganization=${parentOrganization}`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(
          `Error while adding users to project role from ${JSON.stringify(patches)}: ${error}`
        )
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle="Remove Project Access">
      <Header>Remove Project Access</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                {modalLoading ? (
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    xs={{ size: 12 }}
                    {...css(styles.loader)}
                  >
                    <PropagateLoader
                      className="align-self-center"
                      size={20}
                      color={"#9c3"}
                      loading={true}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <div style={{ opacity: modalLoading ? ".5" : "1" }}>
                  <Row className="justify-content-center mb-2">
                    <Col
                      xs={{ size: 8 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Form
                        method="POST"
                        onSubmit={handleSubmit}
                        className="justify-content-center"
                        style={{ width: "100%" }}
                      >
                        <FormGroup>
                          <Label for="role">Role</Label>
                          <Input
                            type="select"
                            name="role"
                            id="role"
                            value={role}
                            onChange={event => setRole(event.target.value)}
                          >
                            <option value="admin">Admins</option>
                            <option value="controller">Controllers</option>
                            {!!selectProject?.gitlabURL ? (
                              <>
                                <option value="maintainer">Maintainers</option>
                                <option value="developer">Developers</option>
                                <option value="reporter">Reporters</option>
                              </>
                            ) : null}
                            {!!selectProject?.wikiURL ? (
                              <option value="editor">Editors</option>
                            ) : null}
                          </Input>
                        </FormGroup>
                        <FormGroup className="mt-3">
                          <UserPicker
                            role={selectProject.projectSlug + "-" + role}
                            name="users"
                            id="users"
                            whitelist={whitelist}
                            selectUsers={users => setSelectUsers(users)}
                          />
                        </FormGroup>
                        <Row className="justify-content-center mb-2" id="wrapper">
                          <Button
                            type="submit"
                            onClick={handleSubmit}
                            color={"primary"}
                            disabled={
                              selectUsers.length === 0 ||
                              modalLoading ||
                              buttonTooltip ||
                              (!index && !parentOrganization)
                            }
                            style={{ padding: 0 }}
                          >
                            {savingData ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              >
                                <span className="sr-only">Saving...</span>
                              </span>
                            ) : (
                              <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                                Remove
                              </div>
                            )}
                          </Button>
                          <Tooltip
                            placement="top"
                            isOpen={buttonTooltip && tooltipOpen}
                            autohide={false}
                            container="wrapper"
                            target="submit"
                            toggle={toggle}
                          >
                            {buttonTooltip}
                          </Tooltip>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default Remove
const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
  clearSearch: {
    right: "0 !important",
    height: "100%",
  },
  searchBtn: {
    outline: "none !important",
  },
  filterInput: {
    width: "100%",
  },
  filterInputGroup: {
    marginBottom: "20px",
  },
}
