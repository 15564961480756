import React, { useState, useEffect, useRef } from "react"
import { Form, Button, Row, Col, FormGroup, Label, Input, CustomInput, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/projects"
import ProjectView from "../../../components/support/user/project-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const CreateRepo = () => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const [artifactoryRepos, setArtifactoryRepos] = useState([])
  const [selectArtifactoryRepokey, setSelectArtifactoryRepokey] = useState(null)
  const [selectArtifactoryRepoID, setSelectArtifactoryRepoID] = useState(null)

  function getParentOrganization() {
    return parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) urlparams = `?parentOrganization=${getParentOrganization()}`
    return urlparams
  }

  const [file, setFile] = useState({
    sourceFilePath: "",
    destinationFilePath: "",
    checkSum: "",
    legacy: false,
    modification: true,
    packageName: "",
    licenseIdentifier: "",
    copyrightText: "",
    licenseText: "",
    packageVersion: "",
  })

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    setButtonTooltip("")
    if (selectProject) {
      if (selectProject.status !== "active") {
        setButtonTooltip("The specified Project is not active.")
      } else {
        get_project_artifactory_repos()
      }
      setFile({
        sourceFilePath: "",
        destinationFilePath: "",
        checkSum: "",
        legacy: false,
        modification: true,
        packageName: "",
        licenseIdentifier: "",
        copyrightText: "",
        licenseText: "",
        packageVersion: "",
      })
    }
  }, [selectProject])

  const get_project_artifactory_repos = () => {
    var urlparams = getAdditionalUrlParams()
    setArtifactoryRepos([])
    setSelectArtifactoryRepokey(null)
    setSelectArtifactoryRepoID(null)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          index
            ? `projects/${selectProject.projectSlug}/artifactory/repos?index=${index}&all=true`
            : `projects/${selectProject.projectSlug}/artifactory/repos${urlparams}&all=true`,
          accessToken
        )
      )
      .then(response => {
        const data = [...(response.data || [])].map(r => ({
          repoKey: r.artifactRepoID,
          repoName: r.repoName,
        }))
        setArtifactoryRepos(data)
        setSelectArtifactoryRepoID(data[0] ? data[0].repoKey : null)
        setSelectArtifactoryRepokey(data[0] ? data[0].repoName : null)
        if (!data[0]) setButtonTooltip("The specified Project already has Artifacts service.")
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while getting artifactory repos: ${error}`)
      })
  }

  if (loading) {
    return <Loading />
  }

  const fileChange = e => {
    setFile({ ...file, [e.name]: e.value })
  }

  async function send_request() {
    var urlparams = getAdditionalUrlParams()
    var urlparamsAmp = urlparams.replace("?", "&")
    let params = {
      sourceFilePath: file.sourceFilePath,
      destinationFilePath: file.destinationFilePath,
      legacy: file.legacy,
    }
    if (!file.legacy) {
      params.packageName = file.packageName
      params.licenseIdentifier = file.licenseIdentifier
      params.copyrightText = file.copyrightText
      params.licenseText = file.licenseText
      params.packageVersion = file.packageVersion
      params.modification = file.modification
    }
    if (file.checkSum) params.checkSum = file.checkSum
    if (!index) {
      params.parentOrganization = parentOrganization
      // params.raiseOnBehalfOf = raiseOnBehalfOf
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/artifactory/${selectArtifactoryRepoID}/file?index=${index}${urlparamsAmp}`
            : `ui/support/projects/${selectProject.projectSlug}/artifactory/${selectArtifactoryRepoID}/file${urlparams}`,
          params,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while upload an artifact from ${JSON.stringify(params)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  function onChangeEvent(event) {
    var index = event.target.selectedIndex
    setSelectArtifactoryRepoID(event.target.value)
    setSelectArtifactoryRepokey(event.target[index].text)
  }

  return (
    <Layout pageTitle="Upload an Artifact">
      <Header>Upload an Artifact</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                <Row className="mb-2">
                  <ProjectView
                    project={{ ...selectProject, parentOrganization: name }}
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Row className="justify-content-center mb-2">
                  <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                    <Form
                      method="POST"
                      onSubmit={handleSubmit}
                      className="justify-content-center"
                      style={{ width: "90%" }}
                    >
                      <FormGroup>
                        <Label for="repoName">Repo Name</Label>
                        <Input
                          type="select"
                          name={selectArtifactoryRepokey}
                          id="repoKey"
                          value={selectArtifactoryRepoID}
                          onChange={event => onChangeEvent(event)}
                        >
                          {artifactoryRepos.map(p => (
                            <option value={p.repoKey} name={p.repoName} key={p.repoName}>
                              {p.repoName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="sourceFilePath">Source File Path</Label>
                        <Input
                          type="text"
                          name="sourceFilePath"
                          id="sourceFilePath"
                          value={file.sourceFilePath}
                          onChange={event => fileChange(event.target)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="destinationFilePath">Destination File Path</Label>
                        <Input
                          type="text"
                          name="destinationFilePath"
                          id="destinationFilePath"
                          value={file.destinationFilePath}
                          onChange={event => fileChange(event.target)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="checkSum">CheckSum</Label>
                        <Input
                          type="text"
                          name="checkSum"
                          id="checkSum"
                          value={file.checkSum}
                          onChange={event => fileChange(event.target)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CustomInput
                          type="switch"
                          id="legacy"
                          name="legacy"
                          label="Legacy"
                          size={"lg"}
                          checked={file.legacy}
                          onChange={() => fileChange({ name: "legacy", value: !file.legacy })}
                        />
                      </FormGroup>
                      {file.legacy ? null : (
                        <div
                          style={{
                            padding: "1rem 2rem",
                            background: "#fafafa",
                            marginBottom: "1rem",
                          }}
                        >
                          <h5>Metadata</h5>
                          <FormGroup>
                            <Label for="packageName">Package Name</Label>
                            <Input
                              type="text"
                              name="packageName"
                              id="packageName"
                              value={file.packageName}
                              onChange={event => fileChange(event.target)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="licenseIdentifier">License Identifier</Label>
                            <Input
                              type="text"
                              name="licenseIdentifier"
                              id="licenseIdentifier"
                              value={file.licenseIdentifier}
                              onChange={event => fileChange(event.target)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="copyrightText">Copyright</Label>
                            <Input
                              type="text"
                              name="copyrightText"
                              id="copyrightText"
                              value={file.copyrightText}
                              onChange={event => fileChange(event.target)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="licenseText">License</Label>
                            <Input
                              type="text"
                              name="licenseText"
                              id="licenseText"
                              value={file.licenseText}
                              onChange={event => fileChange(event.target)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="packageVersion">Package Version</Label>
                            <Input
                              type="text"
                              name="packageVersion"
                              id="packageVersion"
                              value={file.packageVersion}
                              onChange={event => fileChange(event.target)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="modification">Modification</Label>
                            <Input
                              type="select"
                              name="modification"
                              id="modification"
                              value={file.modification}
                              onChange={event => fileChange(event.target)}
                            >
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </Input>
                          </FormGroup>
                        </div>
                      )}
                      <Col style={{ textAlign: "center" }} id="wrapper">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          color={"primary"}
                          disabled={buttonTooltip || (!index && !parentOrganization)}
                          style={{ padding: 0 }}
                        >
                          {savingData ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Saving...</span>
                            </span>
                          ) : (
                            <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                              Upload file
                            </div>
                          )}
                        </Button>
                        <Tooltip
                          placement="top"
                          isOpen={buttonTooltip && tooltipOpen}
                          autohide={false}
                          container="wrapper"
                          target="submit"
                          toggle={toggle}
                        >
                          {buttonTooltip}
                        </Tooltip>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default CreateRepo
