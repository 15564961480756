import React, { useState, useEffect, useRef } from "react"
import { Form, Button, Row, Col, InputGroupAddon, InputGroup, Input } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/projects"
import ProjectView from "../../../components/support/user/project-view"
import _ from "lodash"
import { css } from "glamor"
import { PropagateLoader } from "react-spinners"
import {
  Grid,
  makeStyles,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button as MateriaUiButton,
} from "@material-ui/core"
import { MdClose } from "react-icons/md"
import { useSearch } from "../../../hooks/use-search"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

function intersection(checked, checkeData) {
  const checkedData = _.filter(checkeData, item => {
    return checked.indexOf(item.value) !== -1
  })
  return checkedData
}

const DeleteProject = () => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])
  const [originRight, setOriginRight] = useState([])
  const [checked, setChecked] = useState([])
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)
  const [rightValue, setRightValue] = useState("")
  const [ticketLink, setTicketLink] = useState("")
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  const useStyles = makeStyles(theme => ({
    root: {
      marginTop: "15px",
      marginBottom: "15px",
    },
    paper: {
      width: 330,
      height: 300,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }))
  const classes = useStyles()

  const callback = v => {
    const searchValue = v?.target?.value || ""
    getOriginLeft(searchValue)
  }
  const { search: leftValue, setSearch: setLeftValue, callback: onLeftSearch } = useSearch(callback)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    setLeft([])
    setRight([])
    setChecked([])
    setOriginRight([])
    setLeftValue("")
    setRightValue("")
  }, [selectProject]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />
  }

  const onRightSearch = v => {
    const searchValue = v?.target?.value || ""
    setRightValue(searchValue)
    setRight(searchList(searchValue, originRight))
  }

  const handleAllRight = () => {
    const rightList = right.concat(left)
    setRight(rightList)
    setOriginRight(rightList)
    setLeft([])
    setChecked([])
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
    setOriginRight([])
    setChecked([])
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(right.filter(r => !rightChecked.includes(r)))
    setOriginRight(originRight.filter(r => !rightChecked.includes(r)))
    setChecked([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setOriginRight(originRight.concat(leftChecked))
    setLeft(left.filter(l => !leftChecked.includes(l)))
    setChecked([])
  }

  const restartList = () => {
    getOriginLeft()
  }

  const handleToggle = item => () => {
    const currentIndex = checked.indexOf(item.value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(item.value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const searchList = (value, allData) => {
    let filterData = allData
    if (value) {
      filterData = _.filter(allData, item => {
        return _.upperCase(item.title).indexOf(_.upperCase(value)) !== -1
      })
    }
    return filterData
  }

  const get_user_list = username => {
    setModalLoading(true)
    let users = []
    let url = index
      ? `users?page=1&limit=10&query=${username}&index=${index}`
      : `users?page=1&limit=10&query=${username}&parentOrganization=${parentOrganization}`
    return new Promise((resolve, reject) => {
      getAccessTokenSilently()
        .then(accessToken => fetch_get(url, accessToken))
        .then(response => {
          if (response?.data) {
            response.data.forEach(s => {
              users.push({
                value: s["username"],
                title: s["displayName"],
              })
            })
            resolve(users)
          }
        })
    })
  }

  const getOriginLeft = (value = "") => {
    if (value) {
      get_user_list(value).then(res => {
        setModalLoading(false)
        const newLeft = _.differenceBy(res, right, "value")
        setLeft(newLeft)
      })
    } else {
      setLeft([])
    }
  }

  async function send_request() {
    let patches = { userNames: originRight.map(u => u.value) }
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/export?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/export`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while exporting project from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  const customList = left => (
    <>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {left.map(item => {
            const labelId = `transfer-list-item-${item.value}-label`
            return (
              <ListItem key={item.value} role="listitem" button onClick={handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item.value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.title}`} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  )

  return (
    <Layout pageTitle="Export Project">
      <Header>Export Project</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                <Row className="mb-2">
                  <ProjectView
                    project={{ ...selectProject, parentOrganization: name }}
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Form method="POST" onSubmit={handleSubmit} className="justify-content-center">
                  <Row className="justify-content-center mb-2">
                    <Col
                      xs={{ size: 8 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {modalLoading ? (
                        <Col
                          className="d-flex justify-content-center align-items-center"
                          xs={{ size: 12 }}
                          {...css(styles.loader)}
                        >
                          <PropagateLoader
                            className="align-self-center"
                            size={20}
                            color={"#9c3"}
                            loading={true}
                          />
                        </Col>
                      ) : (
                        <></>
                      )}
                      <div style={{ opacity: modalLoading ? ".5" : "1" }}>
                        <Grid
                          container
                          spacing={2}
                          justify="center"
                          alignItems="center"
                          className={classes.root}
                        >
                          <Grid item style={{ width: 772 }}>
                            <span>
                              Please select users, they will be informed by email when the export is
                              completed.
                            </span>
                          </Grid>
                          <>
                            <Grid item>
                              <InputGroup {...css(styles.filterInputGroup)}>
                                <Input
                                  type="text"
                                  value={leftValue}
                                  placeholder="Search for a user..."
                                  onChange={event => onLeftSearch(event)}
                                  {...css(styles.filterInput)}
                                />
                                {leftValue !== "" && (
                                  <InputGroupAddon
                                    className="clear_search"
                                    addonType="append"
                                    {...css(styles.clearSearch)}
                                  >
                                    <MateriaUiButton
                                      {...css(styles.searchBtn)}
                                      onClick={() => {
                                        setLeftValue("")
                                        restartList()
                                      }}
                                    >
                                      <MdClose />
                                    </MateriaUiButton>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                              {customList(left, "left")}
                            </Grid>
                            <Grid item>
                              <Grid container direction="column" alignItems="center">
                                <MateriaUiButton
                                  variant="outlined"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleAllRight}
                                  disabled={left.length === 0}
                                  aria-label="move all right"
                                >
                                  ≫
                                </MateriaUiButton>
                                <MateriaUiButton
                                  variant="outlined"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleCheckedRight}
                                  disabled={leftChecked.length === 0}
                                  aria-label="move selected right"
                                >
                                  &gt;
                                </MateriaUiButton>
                                <MateriaUiButton
                                  variant="outlined"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleCheckedLeft}
                                  disabled={rightChecked.length === 0}
                                  aria-label="move selected left"
                                >
                                  &lt;
                                </MateriaUiButton>
                                <MateriaUiButton
                                  variant="outlined"
                                  size="small"
                                  className={classes.button}
                                  onClick={handleAllLeft}
                                  disabled={right.length === 0}
                                  aria-label="move all left"
                                >
                                  ≪
                                </MateriaUiButton>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <InputGroup {...css(styles.filterInputGroup)}>
                                <Input
                                  type="text"
                                  value={rightValue}
                                  placeholder="Search for a user..."
                                  onChange={event => onRightSearch(event)}
                                  {...css(styles.filterInput)}
                                />
                                {rightValue !== "" && (
                                  <InputGroupAddon
                                    className="clear_search"
                                    addonType="append"
                                    {...css(styles.clearSearch)}
                                  >
                                    <MateriaUiButton
                                      {...css(styles.searchBtn)}
                                      onClick={() => {
                                        setRightValue("")
                                        onRightSearch("")
                                      }}
                                    >
                                      <MdClose />
                                    </MateriaUiButton>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                              {customList(right, "right")}
                            </Grid>
                          </>
                        </Grid>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center mb-2">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      color={"primary"}
                      disabled={
                        originRight.length === 0 || modalLoading || (!index && !parentOrganization)
                      }
                    >
                      {savingData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Saving...</span>
                        </span>
                      ) : (
                        "Export project"
                      )}
                    </Button>
                  </Row>
                </Form>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default DeleteProject
const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
  clearSearch: {
    right: "0 !important",
    height: "100%",
  },
  searchBtn: {
    outline: "none !important",
  },
  filterInput: {
    width: "100%",
  },
  filterInputGroup: {
    marginBottom: "20px",
  },
}
