import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Form, FormGroup, Button, Label, Input, CustomInput } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_get, fetch_post } from "../../../utils/fetch"
import { toast } from "react-toastify"
import { PropagateLoader } from "react-spinners"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = "success"
const ERROR = "error"

const CreateProject = props => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [eccn, setEccn] = useState("")
  const [projectName, setProjectName] = useState("")
  const [originalProjectName] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [originalProjectDescription] = useState("")
  const [projectSlug, setProjectSlug] = useState("")
  const [originalProjectSlug, setOriginalProjectSlug] = useState("")
  const [projectVisibility, setProjectVisibility] = useState("public")
  const [originalProjectVisibility] = useState("public")
  const [gitlabService, setGitlabService] = useState(false)
  const [wikiService, setWikiService] = useState(false)
  const [artifactoryService, setArtifactoryService] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [changes, setChanges] = useState("")
  const [gotError] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [parentOrganization, setParentOrganization] = useState("")
  const [organization, setOrganization] = useState("")
  const [servicesEnable, setServicesEnable] = useState({})
  const [orgList, setOrgList] = useState([])
  const [orgNewList, setOrgNewList] = useState([])
  const [parList, setParList] = useState([])
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()

  const [orgLoading, setOrgLoading] = useState(true)
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (parentOrganization !== "" && parList.length > 0) {
      const orgItem = orgList[parentOrganization]
      setOrgNewList(orgItem)
      setOrganization(orgItem[0])
    }
  }, [parentOrganization, orgList, parList])

  useEffect(() => {
    if (!loading) {
      setPageLoading(true)
      let mounted = true
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            index ? `ui/organizations?ui=1&index=${index}` : "ui/organizations?ui=1",
            accessToken
          )
        )
        .then(res => {
          if (mounted) {
            const parListArr = Object.keys(res)
            const orgItem = res[parListArr[0]] || []
            setParList(parListArr)
            setOrgList(res)
            setOrgNewList(orgItem)
            setParentOrganization(parListArr[0] || "")
            setOrganization(orgItem[0] || "")
            setPageLoading(false)
            setOrgLoading(false)
          }
        })
      return function cleanup() {
        mounted = false
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    const slug = projectName
      .replace(/[^A-Za-z0-9\-\s]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
    setOriginalProjectSlug(slug)
    if (projectSlug === originalProjectSlug) {
      setProjectSlug(slug)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName])

  useEffect(() => {
    var changeLog = []
    if (projectName !== originalProjectName) {
      changeLog.push("Set project name")
    }

    if (projectDescription !== originalProjectDescription) {
      changeLog.push("Set project description")
    }
    if (projectSlug !== originalProjectSlug) {
      changeLog.push("Set project slug")
    }
    if (eccn !== "") {
      changeLog.push("Set project ECCN")
    }
    if (projectVisibility !== originalProjectVisibility) {
      changeLog.push("Set project visibility")
    }
    if (parentOrganization !== "") {
      changeLog.push("Set parentOrganization")
    }
    if (organization !== "") {
      changeLog.push("Set organization")
    }
    if (gitlabService !== false) {
      changeLog.push("Enable Git service")
    }
    if (artifactoryService !== false) {
      changeLog.push("Enable Artifacts service")
    }
    if (projectVisibility === "public" && wikiService !== false) {
      changeLog.push("Enable Wiki service")
    }
    setChanges(changeLog)
    if (changeLog.length === 0) {
      setFormInvalid(true)
    } else {
      setFormInvalid(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectName,
    projectDescription,
    projectSlug,
    eccn,
    projectVisibility,
    gitlabService,
    wikiService,
    artifactoryService,
    originalProjectName,
    originalProjectDescription,
    originalProjectSlug,
    originalProjectVisibility,
  ])

  const getParentOrgInfo = () => {
    getAccessTokenSilently()
    .then(accessToken =>
      fetch_get(
        `parentOrganization/services/${parentOrganization}`,
        accessToken
      )
    )
    .then(res => {
      setServicesEnable(res)
    }).catch(()=>{
    })
  }

  useEffect(() => {
    if (
      parentOrganization === undefined ||
      parentOrganization === null ||
      parentOrganization === ""
    )
      return
    getParentOrgInfo()
  }, [parentOrganization])

  if (loading) {
    return <Loading />
  }

  const handleReset = () => {
    console.log("handleReset triggered")
    setProjectName(originalProjectName)
    setProjectDescription(originalProjectDescription)
    setProjectSlug(originalProjectSlug)
    setProjectVisibility(originalProjectVisibility)
    setEccn("")
    setGitlabService(false)
    setArtifactoryService(false)
    setWikiService(false)
  }

  async function getResultStatus() {
    let patches = {}
    patches["name"] = projectName
    patches["description"] = projectDescription
    patches["projectSlug"] = projectSlug
    if (eccn) patches["eccn"] = eccn
    patches["visibility"] = projectVisibility
    patches["parentOrganization"] = parentOrganization
    patches["organization"] = organization
    if(servicesEnable.git_service_enabled) patches["gitlab"] = gitlabService
    if(servicesEnable.artifactory_service_enabled) patches["artifactory"] = artifactoryService
    if(servicesEnable.wiki_service_enabled) {
      patches["wiki"] = wikiService
    }
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index ? `ui/support/projects?index=${index}` : `ui/support/projects`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        setPageLoading(false)
        Sentry.captureException(error)
        console.log(`Error while creating project from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }
  const handleSubmit = async event => {
    event.preventDefault()
    setSavingData(true)
    getResultStatus().then(res => {
      setSavingData(false)
      if (res.status === SUCCESS) {
        setTicketLink(res.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${res.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  return (
    <Layout pageTitle="Create Project">
      <Header>Create Project</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            isCreate={true}
            orgLoading={orgLoading}
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            orgList={orgList}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row>
              <Col>
                {pageLoading ? (
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    xs={{ size: 12 }}
                    sm={{ size: 4, offset: 4 }}
                  >
                    <PropagateLoader
                      className="align-self-center"
                      size={20}
                      color={"#9c3"}
                      loading={true}
                    />
                  </Col>
                ) : !pageLoading && gotError ? (
                  <p>
                    An error has occurred while trying to retrieve the information for this project.
                    If this continues, please raise a support ticket.
                  </p>
                ) : (
                  <>
                    <Col>
                      <Form method="POST" onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label for="projectName">Name</Label>
                          <Input
                            type="text"
                            name="projectName"
                            id="projectName"
                            value={projectName}
                            disabled={props.viewOnly}
                            onChange={event => setProjectName(event.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="projectDescription">Description</Label>
                          <Input
                            type="textarea"
                            name="projectDescription"
                            id="projectDescription"
                            value={projectDescription}
                            disabled={props.viewOnly}
                            onChange={event => setProjectDescription(event.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="projectSlug">Slug</Label>
                          <Input
                            type="text"
                            name="projectSlug"
                            id="projectSlug"
                            value={projectSlug}
                            onChange={event => setProjectSlug(event.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="projectName">
                            Export Control Classification Number (ECCN)
                          </Label>
                          <Input
                            type="text"
                            name="eccn"
                            id="eccn"
                            value={eccn}
                            disabled={props.viewOnly}
                            onChange={event => setEccn(event.target.value)}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="projectVisibility">Visibility</Label>
                          <Input
                            type="select"
                            name="projectVisibility"
                            id="projectVisibility1111"
                            value={projectVisibility}
                            onChange={event => setProjectVisibility(event.target.value)}
                          >
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label for="organization">Organization</Label>
                          <Input
                            type="select"
                            name="organization"
                            id="organization"
                            value={organization}
                            onChange={event => setOrganization(event.target.value)}
                          >
                            {orgNewList.map((p, index) => (
                              <option value={p} key={index}>
                                {p}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        {(servicesEnable.git_service_enabled || servicesEnable.artifactory_service_enabled || servicesEnable.wiki_service_enabled) && <h5>Services</h5>}
                        {servicesEnable.git_service_enabled && <FormGroup>
                          <CustomInput
                            type="switch"
                            id="gitlabService"
                            name="Git"
                            label="Git"
                            size={"lg"}
                            checked={gitlabService}
                            disabled={props.viewOnly}
                            onChange={() => setGitlabService(!gitlabService)}
                          />
                        </FormGroup>}
                        {servicesEnable.artifactory_service_enabled && <FormGroup>
                          <CustomInput
                            type="switch"
                            id="artifactorySwitch"
                            name="artifactoryService"
                            label="Artifacts"
                            size={"lg"}
                            checked={artifactoryService}
                            disabled={props.viewOnly}
                            onChange={() => setArtifactoryService(!artifactoryService)}
                          />
                        </FormGroup>}
                        {servicesEnable.wiki_service_enabled? (
                          <FormGroup>
                            <CustomInput
                              type="switch"
                              id="wikiService"
                              name="wikiService"
                              label="Wiki"
                              size={"lg"}
                              checked={wikiService}
                              disabled={props.viewOnly}
                              onChange={() => setWikiService(!wikiService)}
                            />
                          </FormGroup>
                        ) : null}

                        <>
                          <Button
                            className="mr-2"
                            onClick={handleReset}
                            disabled={formInvalid || savingData}
                          >
                            Reset changes
                          </Button>
                          <Button
                            type="submit"
                            className="mr-2"
                            color={"primary"}
                            onClick={handleSubmit}
                            disabled={formInvalid || savingData || (!index && !parentOrganization)}
                          >
                            {savingData ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              >
                                <span className="sr-only">Saving...</span>
                              </span>
                            ) : (
                              "Create project"
                            )}
                          </Button>
                        </>
                      </Form>
                      {!formInvalid ? (
                        <div className="mt-3">
                          <h5>Pending changes</h5>
                          <ul>
                            {changes.map((p, index) => (
                              <li key={index}>{p}</li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </>
                )}
              </Col>
            </Row>
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default CreateProject
