import React, { useState, useEffect, useRef } from "react"
// import { Form, Button, Row, Col, FormGroup, Label, Input, Tooltip } from "reactstrap"
import { Form, Button, Row, Col, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import SearchHeader from "../../../components/support/input-search/users"
import { PropagateLoader } from "react-spinners"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const AddIrcAccess = () => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const childRef = useRef()
  const [selectUser, setSelectUser] = useState(null)
  const [ircVisibility, setIrcVisibility] = useState(false)
  const [summary, setSummary] = useState("")
  const [pageLoading, setPageLoading] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  const InitialiseServiceState = () => {
    setIrcVisibility(false)
    setSummary("")
  }

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectUser) {
      InitialiseServiceState()
      setPageLoading(true)
      // Now check the services
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            _.isNil(index)
              ? `users/${selectUser.username}/services`
              : `users/${selectUser.username}/services?index=${index}`,
            accessToken
          )
        )
        .then(response => {
          setPageLoading(false)
          setIrcVisibility(response.hasOwnProperty("irc"))
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while processing user services: ${error}`)
          setPageLoading(false)
        })
    }
  }, [selectUser]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    // Build up a list of patches
    let patches = {
      username: selectUser.username,
      email: selectUser.externalEmail,
    }
    if (summary) patches.summary = summary
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(index ? `ui/support/irc` : `ui/support/irc?index=${index}`, patches, accessToken)
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while adding access to IRC from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle={`Request User Access to IRC`}>
      <Header>Request User Access to IRC</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectUser(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectUser ? (
              pageLoading ? (
                <Col className="d-flex justify-content-center align-items-center">
                  <PropagateLoader
                    className="align-self-center"
                    size={20}
                    color={"#9c3"}
                    loading={true}
                  />
                </Col>
              ) : (
                <>
                  <Row className="justify-content-center mb-2">
                    <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                      <Form
                        method="POST"
                        onSubmit={handleSubmit}
                        className="justify-content-center"
                        style={{ width: "100%" }}
                      >
                        {/* <FormGroup>
                      <Label for="subGroups">Summary</Label>
                      <Input
                        type="text"
                        name="summary"
                        id="summary"
                        value={summary}
                        onChange={event => setSummary(event.target.value)}
                      />
                    </FormGroup> */}
                        <Col style={{ textAlign: "center" }} id="usersWrapper">
                          <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={
                              savingData || ircVisibility || (!index && !parentOrganization)
                            }
                            className="mr-2"
                            color={"primary"}
                            style={{ padding: 0 }}
                          >
                            {savingData ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              >
                                <span className="sr-only">Saving...</span>
                              </span>
                            ) : (
                              <div id="create" style={{ padding: "0.375rem 0.75rem" }}>
                                Add access
                              </div>
                            )}
                          </Button>
                          <Tooltip
                            placement="top"
                            isOpen={ircVisibility && tooltipOpen}
                            autohide={false}
                            container="usersWrapper"
                            target="create"
                            toggle={toggle}
                          >
                            The specified user already has an irc account.
                          </Tooltip>
                        </Col>
                      </Form>
                    </Col>
                  </Row>
                </>
              )
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default AddIrcAccess
