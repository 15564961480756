import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Form, Button, Label, FormGroup, Input } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_patch, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import { PropagateLoader } from "react-spinners"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import SearchHeader from "../../../components/support/input-search/users"
import UserView from "../../../components/support/user/user-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const ModifyUser = () => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0()
  const [selectUser, setSelectUser] = useState(null)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [role, setRole] = useState("none")

  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [changes, setChanges] = useState("")
  const [pageLoading, setPageLoading] = useState(true)
  const [roleList, setRoleList] = useState([])
  const [ticketLink, setTicketLink] = useState("")
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectUser) {
      setFirstName(selectUser.firstName)
      setLastName(selectUser.lastName)
      setDisplayName(selectUser.displayName)
      setRole(selectUser.role)
    }
  }, [selectUser])

  useEffect(() => {
    setPageLoading(true)
    let mounted = true

    const get_roles_promise = new Promise((resolve, reject) => {
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(isSuperAdmin ? "roles" : `roles?index=${index}`, accessToken)
        )
        .then(response => {
          resolve(response)
        })
    })
    if (user && isAuthenticated) {
      get_roles_promise.then(res => {
        if (mounted) {
          if (index) {
            setRoleList(["none", ...res.filter(i => i !== "super-admin")])
          } else {
            setRoleList(["none", ...res])
          }

          setPageLoading(false)
        }
      })
    }
    return function cleanup() {
      mounted = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated, getAccessTokenSilently])

  useEffect(() => {
    const checkForChanges = () => {
      let changeLog = []
      if (firstName !== selectUser.firstName) {
        changeLog.push(`Set first name`)
      }
      if (lastName !== selectUser.lastName) {
        changeLog.push(`Set last name`)
      }
      if (displayName !== selectUser.displayName) {
        changeLog.push("Set display Name")
      }
      if (role !== selectUser.role) {
        changeLog.push(`Set role`)
      }
      setChanges(changeLog)
      if (changeLog.length === 0) {
        setFormInvalid(true)
      } else {
        setFormInvalid(false)
      }
    }
    if (selectUser) {
      checkForChanges()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, displayName, selectUser, role])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    // Build up a list of patches
    let patches = {}
    if (firstName !== "") patches["firstName"] = firstName
    if (lastName !== "") patches["lastName"] = lastName
    if (role !== "none") patches["role"] = role
    if (displayName !== "") patches["displayName"] = displayName
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_patch(
          _.isNil(index)
            ? `ui/support/users/${selectUser.username}`
            : `ui/support/users/${selectUser.username}?index=${index}`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while modifying user from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle="Modify User">
      <Header>Modify User</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectUser(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectUser ? (
              <>
                <Row className="mb-2">
                  <UserView
                    user={selectUser}
                    mode="v"
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Row className="justify-content-center mb-2">
                  <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                    <>
                      {pageLoading ? (
                        <PropagateLoader
                          className="align-self-center"
                          size={20}
                          color={"#9c3"}
                          loading={true}
                        />
                      ) : (
                        <>
                          <Form method="POST" onSubmit={handleSubmit} style={{ width: "90%" }}>
                            <FormGroup>
                              <Label for="firstName">First Name</Label>
                              <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={firstName}
                                onChange={event => setFirstName(event.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="lastName">Last Name</Label>
                              <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={lastName}
                                onChange={event => setLastName(event.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="displayName">Display Name</Label>
                              <Input
                                type="text"
                                name="displayName"
                                id="displayName"
                                value={displayName}
                                onChange={event => setDisplayName(event.target.value)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="accountRole">Role</Label>
                              <Input
                                type="select"
                                name="accountRole"
                                id="accountRole"
                                value={role}
                                onChange={event => setRole(event.target.value)}
                              >
                                {roleList.map((p, index) => (
                                  <option key={index} value={p}>
                                    {_.startCase(p)}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            <Col style={{ textAlign: "center" }}>
                              <Button
                                type="submit"
                                onClick={handleSubmit}
                                disabled={
                                  formInvalid || savingData || (!index && !parentOrganization)
                                }
                                color={"primary"}
                              >
                                {savingData ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  >
                                    <span className="sr-only">Saving...</span>
                                  </span>
                                ) : (
                                  "Modify user"
                                )}
                              </Button>
                            </Col>
                            {!formInvalid ? (
                              <div className="mt-3">
                                <h5>Pending changes</h5>
                                <ul>
                                  {changes.map((p, index) => (
                                    <li key={index}>{p}</li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Form>
                        </>
                      )}
                    </>
                  </Col>
                </Row>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default ModifyUser
