import React, { useState, useRef, useEffect } from "react"
import { Form, Button, Row, Col, FormGroup, Label, Input, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_delete, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/projects"
import ProjectView from "../../../components/support/user/project-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const DeleteProject = () => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const childRef = useRef()
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const [artifactoryRepos, setArtifactoryRepos] = useState([])
  const [selectArtifactoryRepokey, setSelectArtifactoryRepokey] = useState(null)
  const [selectArtifactoryRepoID, setSelectArtifactoryRepoID] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    setButtonTooltip("")
    if (selectProject) {
      if (selectProject.status !== "active") {
        setButtonTooltip("The specified project is not active.")
      } else {
        get_project_artifactory_repos()
      }
    }
  }, [selectProject])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    let params = {}
    if (!index) {
      params = { parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_delete(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/artifactory/repo/${selectArtifactoryRepoID}?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/artifactory/repo/${selectArtifactoryRepoID}`,
          params,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while deleting artifacts repository: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const get_project_artifactory_repos = () => {
    setArtifactoryRepos([])
    setSelectArtifactoryRepokey(null)
    setSelectArtifactoryRepoID(null)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          index
            ? `projects/${selectProject.projectSlug}/artifactory/repos?index=${index}&all=true`
            : `projects/${selectProject.projectSlug}/artifactory/repos?parentOrganization=${parentOrganization}&all=true`,
          accessToken
        )
      )
      .then(response => {
        const data = [...(response.data || [])].map(r => ({
          repoKey: r.artifactRepoID,
          repoName: r.repoName,
        }))
        setArtifactoryRepos(data)
        setSelectArtifactoryRepoID(data[0] ? data[0].repoKey : null)
        setSelectArtifactoryRepokey(data[0] ? data[0].repoName : null)
        if (!data[0]) setButtonTooltip("The specified project already has Artifacts service.")
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while getting artifactory repos: ${error}`)
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  function onChangeEvent(event) {
    var index = event.target.selectedIndex
    setSelectArtifactoryRepoID(event.target.value)
    setSelectArtifactoryRepokey(event.target[index].text)
  }

  return (
    <Layout pageTitle="Delete Artifacts Repository">
      <Header>Delete Artifacts Repository</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                <Row className="mb-2">
                  <ProjectView
                    project={{ ...selectProject, parentOrganization: name }}
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Row className="justify-content-center mb-2">
                  <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                    <Form
                      method="POST"
                      onSubmit={handleSubmit}
                      className="justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <FormGroup>
                        <Label for="repoName">Repo Name</Label>
                        <Input
                          type="select"
                          name={selectArtifactoryRepokey}
                          id="repoKey"
                          value={selectArtifactoryRepoID}
                          onChange={event => onChangeEvent(event)}
                        >
                          {artifactoryRepos.map(p => (
                            <option value={p.repoKey} name={p.repoName} key={p.repoName}>
                              {p.repoName}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <Row className="justify-content-center mb-2" id="wrapper">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          color={"primary"}
                          disabled={buttonTooltip || (!index && !parentOrganization)}
                          style={{ padding: 0 }}
                        >
                          {savingData ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Saving...</span>
                            </span>
                          ) : (
                            <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                              Delete repository
                            </div>
                          )}
                        </Button>
                        <Tooltip
                          placement="top"
                          isOpen={buttonTooltip && tooltipOpen}
                          autohide={false}
                          container="wrapper"
                          target="submit"
                          toggle={toggle}
                        >
                          {buttonTooltip}
                        </Tooltip>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default DeleteProject
