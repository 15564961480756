import React, { useState, useEffect } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"

export default function Tags(props) {
  const [inputText, setinputText] = useState("")
  const [filterUsers, setFilterUsers] = useState([])

  useEffect(() => {
    if (inputText) {
      const users = props.whitelist.filter(
        i =>
          i.displayName.toLowerCase().includes(inputText.toLowerCase()) ||
          i.username.toLowerCase().includes(inputText.toLowerCase()) ||
          i.email.toLowerCase().includes(inputText.toLowerCase())
      )
      setFilterUsers(users)
    } else {
      setFilterUsers([])
    }
  }, [inputText]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      key={props.role}
      noOptionsText={"No user to select."}
      options={filterUsers}
      getOptionLabel={option => option.displayName}
      onInputChange={(event, value, reason) => {
        setinputText(value)
      }}
      onChange={(event, value, reason) => {
        props.selectUsers(value)
      }}
      filterSelectedOptions
      renderOption={option => {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div style={{ width: "100%", padding: "6px 16px" }}>{option.displayName}</div>
          </>
        )
      }}
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Search for users" placeholder="Users" />
      )}
    />
  )
}
