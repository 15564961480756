import React, { useState, useRef, useEffect } from "react"
import { Form, Input, FormGroup, Button, Label, Row, Col } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"
import { fetch_post } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"

const SUCCESS = 0
const ERROR = 1

const ServiceRequest = () => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [savingData, setSavingData] = useState(false)
  const [summary, setSummary] = useState("")
  const [priority, setPriority] = useState(3)
  const [description, setDescription] = useState("")
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    let params = { summary, priority, description }
    if (!index) {
      params = { ...params, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          _.isNil(index) ? `ui/support/issues/service` : `ui/support/issues/service?index=${index}`,
          params,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(
          `Error while changing to your account or the configuration of a CodeLinaro service or feature from ${JSON.stringify(
            {
              summary,
              priority,
              description,
            }
          )}: ${error}`
        )
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle={`Service Request`}>
      <Header>Service Request</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="justify-content-center">
                <Form method="POST" onSubmit={handleSubmit} className="justify-content-center">
                  <FormGroup>
                    <Label>Summary</Label>
                    <Input
                      id="summary"
                      type="text"
                      name="summary"
                      value={summary}
                      onChange={event => setSummary(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Priority</Label>
                    <Input
                      type="select"
                      name="priority"
                      id="priority"
                      value={priority}
                      onChange={event => setPriority(event.target.value)}
                    >
                      {["Critical", "High", "Medium", "Low"].map((p, index) => (
                        <option value={index + 1} key={index}>
                          {p}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      rows={10}
                      value={description}
                      onChange={event => setDescription(event.target.value)}
                    />
                  </FormGroup>
                  <Row className="justify-content-center mb-2">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={savingData || (!index && !parentOrganization)}
                      color={"primary"}
                    >
                      {savingData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Saving...</span>
                        </span>
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default ServiceRequest
