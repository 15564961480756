import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Form, FormGroup, Label, Input, Button, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import SearchHeader from "../../../components/support/input-search/projects"
import SearchSubGroup from "../../../components/support/input-search/sub-group"
// import ProjectView from "../../../components/support/user/project-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"
import { fetch_post } from "../../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { toast } from "react-toastify"

const CreateSubGroup = () => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()
  const [repo, setRepo] = useState({
    name: "",
    repoSlug: "",
    originalRepoSlug: "",
    issueTracking: "disabled",
    mergeRequests: "disabled",
    requiresDCO: false,
  })
  const [selectSubGroup, setSelectSubGroup] = useState(null)
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectProject) {
      if (selectProject.status !== "active") {
        setButtonTooltip("The specified project is not active.")
      } else if (!selectProject.gitlabURL) {
        setButtonTooltip("The specified project does not have Git service.")
      } else {
        setButtonTooltip("")
      }
      setRepo({
        name: "",
        repoSlug: "",
        originalRepoSlug: "",
        issueTracking: "disabled",
        mergeRequests: "disabled",
        requiresDCO: false,
      })
    }
  }, [selectProject])

  useEffect(() => {
    const slug = repo.name
      .replace(/\s+/g, "-")
      .replace(/[^0-9a-zA-Z_.-]/g, "")
      // .replace(/^-*/g, "")
      // .replace(/(.git|.atom)*$/g, "")
      .trim()
    setRepo({ ...repo, originalRepoSlug: slug })
    if (repo.repoSlug === repo.originalRepoSlug) {
      setRepo({ ...repo, repoSlug: slug, originalRepoSlug: slug })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repo.name])

  if (loading) {
    return <Loading />
  }

  const repoChange = e => {
    setRepo({ ...repo, [e.name]: e.value })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    let params = {
      repoName: repo.name,
      issueTracking: repo.issueTracking,
      mergeRequests: repo.mergeRequests,
      requiresDCO: repo.requiresDCO.toString() === "true" ? true : false,
    }
    if (repo.repoSlug) params.slug = repo.repoSlug
    if (selectSubGroup) params.repoPath = selectSubGroup.relativePath
    if (!index) {
      params = { ...params, parentOrganization }
    }
    setSavingData(true)
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/git/repo?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/git/repo`,
          params,
          accessToken
        )
      )
      .then(response => {
        const {
          data: {
            _links: { web },
          },
        } = response
        setSavingData(false)
        setTicketLink(web)
        childRef.current.toggle()
      })
      .catch(error => {
        setSavingData(false)
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  return (
    <Layout pageTitle="Create a Repository">
      <Header>Create a Repository</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                {/* <Row className="mb-2">
              <ProjectView
                project={{ ...selectProject, parentOrganization: name }}
                mode="v"
                orgIndex={index}
                isSuperAdmin={isSuperAdmin}
                selectParentOrg={name}
              />
            </Row> */}
                <Row className="justify-content-center mb-2">
                  <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                    <Form
                      method="POST"
                      onSubmit={handleSubmit}
                      className="justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <FormGroup style={{ marginTop: "1rem" }}>
                        <SearchSubGroup
                          projectSlug={selectProject.projectSlug}
                          visibility={selectProject.visibility}
                          selectData={data => setSelectSubGroup(data)}
                          parentOrganization={parentOrganization}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={repo.name}
                          onChange={event => repoChange(event.target)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="repoSlug">Slug</Label>
                        <Input
                          type="text"
                          name="repoSlug"
                          id="repoSlug"
                          value={repo.repoSlug}
                          onChange={event => repoChange(event.target)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="issueTracking">Issue Tracking</Label>
                        <Input
                          type="select"
                          name="issueTracking"
                          id="issueTracking"
                          value={repo.issueTracking}
                          onChange={event => repoChange(event.target)}
                        >
                          <option value="enabled">enabled</option>
                          <option value="private">private</option>
                          <option value="disabled">disabled</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="mergeRequests">Merge Requests</Label>
                        <Input
                          type="select"
                          name="mergeRequests"
                          id="mergeRequests"
                          value={repo.mergeRequests}
                          onChange={event => repoChange(event.target)}
                        >
                          <option value="enabled">enabled</option>
                          <option value="private">private</option>
                          <option value="disabled">disabled</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="requiresDCO">Require DCO</Label>
                        <Input
                          type="select"
                          name="requiresDCO"
                          id="requiresDCO"
                          value={repo.requiresDCO}
                          onChange={event => repoChange(event.target)}
                        >
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Input>
                      </FormGroup>
                      <Col style={{ textAlign: "center" }} id="wrapper">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          color={"primary"}
                          disabled={!repo.name || buttonTooltip || (!index && !parentOrganization)}
                          style={{ padding: 0 }}
                        >
                          {savingData ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Saving...</span>
                            </span>
                          ) : (
                            <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                              Create repository
                            </div>
                          )}
                        </Button>
                        <Tooltip
                          placement="top"
                          isOpen={buttonTooltip && tooltipOpen}
                          autohide={false}
                          container="wrapper"
                          target="submit"
                          toggle={toggle}
                        >
                          {buttonTooltip}
                        </Tooltip>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default CreateSubGroup
