import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Form, Button, Label, FormGroup, Input, CustomInput } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { fetch_post, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import { PropagateLoader } from "react-spinners"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { Loading } from "../../../components/loading"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const CreateUserBulk = props => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [displayName, setDisplayName] = useState("")
  const [originalDisplayName, setOriginalDisplayName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [originalFirstName, setOriginalFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [originalLastName, setOriginalLastName] = useState("")
  const [userName, setUserName] = useState("")
  const [originalUserName, setOriginalUserName] = useState("")
  const [status, setStatus] = useState(false)
  const [originalStatus, setOriginalStatus] = useState(false)
  const [role, setRole] = useState("")
  const [originalRole, setOriginalRole] = useState("")
  const [serviceDeskService, setServiceDeskService] = useState(true)
  const [originalServiceDeskService, setOriginalServiceDeskService] = useState(true)
  const [welcomeEmail, setWelcomeEmail] = useState(true)
  const [originalWelcomeEmail, setOriginalWelcomeEmail] = useState(true)
  const [emailService, setEmailService] = useState(false)
  const [mailplan, setMailplan] = useState("non-retention")
  const [originalEmailService, setOriginalEmailService] = useState(false)
  const [gitService, setGitService] = useState(false)
  const [originalGitService, setOriginalGitService] = useState(false)
  const [artifactoryService, setArtifactoryService] = useState(false)
  const [originalArtifactoryService, setOriginalArtifactoryService] = useState(false)
  const [wikiService, setWikiService] = useState(false)
  const [originalWikiService, setOriginalWikiService] = useState(false)

  const [parentOrganization, setParentOrganization] = useState("")
  const [organization, setOrganization] = useState("")
  const [externalEmail, setExternalEmail] = useState("")
  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [selectUser, setSelectUser] = useState(null)
  const [changes, setChanges] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [orgList, setOrgList] = useState([])
  const [roleAll, setRoleAll] = useState([])
  const [roleList, setRoleList] = useState([])
  const [orgNewList, setOrgNewList] = useState([])
  const [parList, setParList] = useState([])
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()

  const [orgLoading, setOrgLoading] = useState(true)
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  function construct_display_name() {
    if (firstName === "") {
      return lastName
    } else if (lastName === "") {
      return firstName
    }

    return `${firstName} ${lastName}`
  }

  async function send_request() {
    // Build up a list of patches
    let patches = {}
    // patches["firstName"] = firstName
    // patches["lastName"] = lastName
    // patches["role"] = role
    // if (userName !== "") {
    //   patches["username"] = userName
    // }
    // if (displayName !== "") {
    //   patches["displayName"] = displayName
    // }
    //patches["externalEmail"] = externalEmail
    // if (isSuperAdmin) {
    patches["parentOrganization"] = parentOrganization
    // }
    // patches["organization"] = organization
    // patches["servicedesk"] = serviceDeskService
    // patches["email"] = emailService
    // patches["gitlab"] = gitService
    // patches["artifactory"] = artifactoryService
    // patches["wiki"] = wikiService
    // patches["welcomeEmail"] = welcomeEmail
    // if (emailService) {
    //   patches["mailplan"] = mailplan
    // }
    if (!index) {
      patches = { parentOrganization }
    } else {
      patches = {}
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          _.isNil(index)
            ? `ui/support/users/bulkuserregistration`
            : `ui/support/users/bulkuserregistration?index=${index}`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while creating user ticket from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  useEffect(() => {
    const checkForChanges = () => {
      let changeLog = []
      if (firstName !== originalFirstName) {
        changeLog.push(`Set first name`)
      }
      if (lastName !== originalLastName) {
        changeLog.push(`Set last name`)
      }
      if (displayName !== "") {
        changeLog.push("Set display Name")
      }
      if (parentOrganization !== "") {
        changeLog.push("Set parentOrganization")
      }
      if (organization !== "") {
        changeLog.push("Set organization")
      }
      if (externalEmail !== "") {
        changeLog.push("Set external email address")
      }
      if (userName !== "") {
        changeLog.push("Set username")
      }
      if (role !== originalRole) {
        changeLog.push(`Set role`)
      }
      if (firstName !== originalFirstName && lastName !== originalLastName) {
        setOriginalDisplayName(construct_display_name())
        if (displayName !== originalDisplayName) {
          setDisplayName(displayName)
        } else {
          setDisplayName(construct_display_name())
        }
      }

      if (externalEmail !== "" && organization !== "") {
        let username = externalEmail.substring(0, externalEmail.lastIndexOf("@"))
        if (parentOrganization !== organization) {
          username = `${organization.toLowerCase().replace(/\s+/g, "_")}_${username}`
        }
        setOriginalUserName(username)
        if (userName !== originalUserName) {
          setUserName(userName)
        } else {
          setUserName(username)
        }
      }
      if (emailService !== originalEmailService) {
        changeLog.push("Enable Email service")
      }
      if (gitService !== originalGitService) {
        changeLog.push("Enable Git service")
      }
      if (artifactoryService !== originalArtifactoryService) {
        changeLog.push("Enable Artifacts service")
      }
      if (wikiService !== originalWikiService) {
        changeLog.push("Enable Wiki service")
      }
      if (!serviceDeskService) {
        changeLog.push("Disable Support service")
      }
      if (!welcomeEmail) {
        changeLog.push("Disable Send Welcome Email")
      }
      setChanges(changeLog)
      if (changeLog.length === 0) {
        setFormInvalid(true)
      } else {
        setFormInvalid(false)
      }
    }

    checkForChanges()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.mode,
    firstName,
    lastName,
    displayName,
    userName,
    originalDisplayName,
    originalUserName,
    parentOrganization,
    organization,
    externalEmail,
    status,
    role,
    emailService,
    gitService,
    artifactoryService,
    wikiService,
    serviceDeskService,
    welcomeEmail,
    originalFirstName,
    originalLastName,
    originalStatus,
    originalRole,
    originalEmailService,
    originalGitService,
    originalArtifactoryService,
    originalWelcomeEmail,
    originalWikiService,
    serviceDeskService,
  ])

  useEffect(() => {
    if (parentOrganization !== "" && parList.length > 0) {
      const orgItem = orgList[parentOrganization]
      setOrgNewList(orgItem)
      setOrganization(orgItem[0])
    }
  }, [parentOrganization, orgList, parList])

  const InitialiseServiceState = () => {
    setDisplayName("")
    setFirstName("")
    setOriginalFirstName("")
    setLastName("")
    setDisplayName("")
    setOriginalDisplayName("")
    setUserName("")
    setOriginalUserName("")
    setOriginalLastName("")
    setOrganization("")
    setExternalEmail("")
    setRole("member")
    setOriginalRole("")
    setStatus(true)
    setOriginalStatus(true)
    setEmailService(false)
    setOriginalEmailService(false)
    setGitService(false)
    setOriginalGitService(false)
    setArtifactoryService(false)
    setOriginalArtifactoryService(false)
    setWelcomeEmail(true)
    setOriginalWelcomeEmail(true)
    setServiceDeskService(true)
    setOriginalServiceDeskService(true)
    setWikiService(false)
    setOriginalWikiService(false)
  }

  useEffect(() => {
    if (!loading) {
      setIsLoading(true)
      InitialiseServiceState()
      let mounted = true
      const url = isSuperAdmin ? "ui/organizations?ui=1" : `ui/organizations?ui=1&index=${index}`
      const get_organizations_promise = new Promise((resolve, reject) => {
        getAccessTokenSilently()
          .then(console.log("hey"))
          .then(accessToken => fetch_get(url, accessToken))
          .then(response => {
            resolve(response)
          })
      })

      const get_roles_promise = new Promise((resolve, reject) => {
        getAccessTokenSilently()
          .then(accessToken => fetch_get("roles", accessToken))
          .then(response => {
            resolve(response)
          })
      })

      Promise.all([get_organizations_promise, get_roles_promise]).then(res => {
        if (mounted) {
          const response = res[0]
          const parListArr = Object.keys(response)
          const orgItem = response[parListArr[0]] || []
          setParList(parListArr)
          setOrgList(response)
          setOrgNewList(orgItem)
          if (isSuperAdmin) {
            setRoleAll(res[1])
            setRoleList(res[1])
          } else {
            setRoleAll(res[1].filter(i => i !== "super-admin"))
            setRoleList(res[1].filter(i => i !== "super-admin"))
          }
          setIsLoading(false)
          setParentOrganization(parListArr[0] || "")
          setOrganization(orgItem[0] || "")
          setOrgLoading(false)
        }
      })

      return function cleanup() {
        mounted = false
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (parentOrganization !== organization) {
      setRoleList(["member"])
      setRole("member")
    } else {
      setRoleList(roleAll)
    }
  }, [parentOrganization, organization]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="Request to create multiple user accounts">
      <Header>Request to Create Multiple User Accounts</Header>
      <Content>
        <p>Please follow the steps below to proceed with this request:</p>
        <ol>
          <li>
            Download this <a href="https://www.codelinaro.org/users.csv">CSV template</a> file.
          </li>
          <li>Enter all required information into the CSV file as it pertains to each user.</li>
          <li>Click the "Create request ticket" button below.</li>
          <li>
            Proceed to the ticket you just created and upload (or drag-and-drop) your CSV file to
            this ticket for processing.
          </li>
          <li>
            Once the request is approved, a CodeLinaro Support Agent will begin processing the CSV
            creating these new accounts and will update you once the request is completed or if
            further clarification is needed.
          </li>
        </ol>

        {isSuperAdmin && (
          <SuperAdminHeader
            isCreate={true}
            orgLoading={orgLoading}
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            orgList={orgList}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row>
              <Col>
                <>
                  {isLoading ? (
                    <Col
                      className="d-flex justify-content-center align-items-center"
                      xs={{ size: 12 }}
                      sm={{ size: 4, offset: 4 }}
                    >
                      <PropagateLoader
                        className="align-self-center"
                        size={20}
                        color={"#9c3"}
                        loading={true}
                      />
                    </Col>
                  ) : (
                    <Col>
                      <Form method="POST" onSubmit={handleSubmit}>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={formInvalid || savingData || (!index && !parentOrganization)}
                          className="mr-2"
                          color={"primary"}
                        >
                          {savingData ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Saving...</span>
                            </span>
                          ) : (
                            "Create request ticket"
                          )}
                        </Button>
                      </Form>
                    </Col>
                  )}
                </>
              </Col>
            </Row>
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default CreateUserBulk
