import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Form, FormGroup, Button, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import SearchHeader from "../../../components/support/input-search/projects"
import SearchRepo from "../../../components/support/input-search/repos"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"
import { fetch_post } from "../../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { toast } from "react-toastify"

const CleanRepo = () => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const childRef = useRef()
  const [selectRepo, setSelectRepo] = useState(null)
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectProject) {
      if (selectProject.status !== "active") {
        setButtonTooltip("The specified project is not active.")
      } else if (!selectProject.gitlabURL) {
        setButtonTooltip("The specified project does not have Git service.")
      } else {
        setButtonTooltip("")
      }
    }
  }, [selectProject])

  if (loading) {
    return <Loading />
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setSavingData(true)
    let params = {}
    if (!index) {
      params = { parentOrganization }
    }
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/git/cleanup/${selectRepo.gitRepoId}?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/git/cleanup/${selectRepo.gitRepoId}`,
          params,
          accessToken
        )
      )
      .then(response => {
        const {
          data: {
            _links: { web },
          },
        } = response
        setSavingData(false)
        setTicketLink(web)
        childRef.current.toggle()
      })
      .catch(error => {
        setSavingData(false)
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  return (
    <Layout pageTitle="Clean a Repository">
      <Header>Clean a Repository</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                <Row className="justify-content-center mb-2">
                  <Col xs={{ size: 8 }} className="d-flex justify-content-center">
                    <Form
                      method="POST"
                      onSubmit={handleSubmit}
                      className="justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <FormGroup style={{ marginTop: "1rem" }}>
                        <SearchRepo
                          projectSlug={selectProject.projectSlug}
                          selectData={data => setSelectRepo(data)}
                          filterService="gitlab"
                          parentOrganization={parentOrganization}
                        />
                      </FormGroup>
                      <Col style={{ textAlign: "center" }} id="wrapper">
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          color={"primary"}
                          disabled={!selectRepo || buttonTooltip || (!index && !parentOrganization)}
                          style={{ padding: 0 }}
                        >
                          {savingData ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            >
                              <span className="sr-only">Saving...</span>
                            </span>
                          ) : (
                            <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                              Clean repo
                            </div>
                          )}
                        </Button>
                        <Tooltip
                          placement="top"
                          isOpen={buttonTooltip && tooltipOpen}
                          autohide={false}
                          container="wrapper"
                          target="submit"
                          toggle={toggle}
                        >
                          {buttonTooltip}
                        </Tooltip>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default CleanRepo
