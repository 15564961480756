import React, { useState, useEffect, useRef } from "react"
import { Form, Button, Row, FormGroup, Col, Input } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post, fetch_get } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"
import SearchHeader from "../../../components/support/input-search/users"
import UserView from "../../../components/support/user/user-view"
import { ServiceDisplayName } from "../../../utils/service-mappings"
import { PropagateLoader } from "react-spinners"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}))

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap()
  return <Tooltip arrow classes={classes} {...props} />
}
const UpdateUserServiceStatus = props => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const [selectUser, setSelectUser] = useState(null)

  const [pageLoading, setPageLoading] = useState(true)
  const [projectInfo, setProjectInfo] = useState({})
  const [removeService, setRemoveService] = useState("")
  const [removeServices, setRemoveServices] = useState([])

  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [changes, setChanges] = useState("")
  const [ticketLink, setTicketLink] = useState("")
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  const InitialiseServiceState = () => {
    setRemoveService("")
    setRemoveServices([])
    setProjectInfo({})
  }

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectUser) {
      setPageLoading(true)
      let mounted = true
      // Now check the services
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            _.isNil(index)
              ? `users/${selectUser.username}/services`
              : `users/${selectUser.username}/services?index=${index}`,
            accessToken
          )
        )
        .then(response => {
          InitialiseServiceState()
          let canRemove = []
          setProjectInfo(response)
          if (response.hasOwnProperty("email")) canRemove.push("email")
          if (response.hasOwnProperty("gitlab")) canRemove.push("gitlab")
          if (response.hasOwnProperty("artifactory")) canRemove.push("artifactory")
          if (response.hasOwnProperty("servicedesk")) canRemove.push("servicedesk")
          if (response.hasOwnProperty("wiki")) canRemove.push("wiki")
          if (mounted) {
            setRemoveServices(canRemove)
            setRemoveService(canRemove[0] || "")
          }
          setPageLoading(false)
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while processing user services: ${error}`)
          setPageLoading(false)
        })

      return function cleanup() {
        mounted = false
      }
    }
  }, [selectUser]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const checkForChanges = () => {
      let changeLog = []
      setChanges(changeLog)
      if (removeService !== "") {
        changeLog.push("Set remove service access")
      }
      if (changeLog.length === 0) {
        setFormInvalid(true)
      } else {
        setFormInvalid(false)
      }
    }
    checkForChanges()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeService])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    // Build up a list of patches
    let patches = {}
    patches["action"] = "remove"
    if (removeService === "servicedesk") patches["servicedesk"] = true
    if (removeService === "email") patches["email"] = true
    if (removeService === "gitlab") patches["gitlab"] = true
    if (removeService === "artifactory") patches["artifactory"] = true
    if (removeService === "wiki") patches["wiki"] = true
    if (!index) {
      patches = { ...patches, parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          _.isNil(index)
            ? `ui/support/users/${selectUser.username}/services`
            : `ui/support/users/${selectUser.username}/services?index=${index}`,
          patches,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while Enabling service access from ${JSON.stringify(patches)}: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  const overViewImg = (projectInfo, serviceName) => {
    const text =
      _.has(projectInfo, serviceName) && projectInfo[serviceName].status === "active"
        ? ServiceDisplayName(serviceName)
        : `${ServiceDisplayName(serviceName)} (Inactive)`
    return (
      <>
        {_.has(projectInfo, serviceName) ? (
          <Col xm={1} sm={1} md={1}>
            <BootstrapTooltip
              title={text}
              placement="top"
              style={{
                cursor: "pointer",
              }}
            >
              <img
                height="30"
                src={
                  projectInfo[serviceName].status === "active"
                    ? `/${serviceName}.svg`
                    : `/${serviceName}_grey.svg`
                }
                alt="service"
              />
            </BootstrapTooltip>
          </Col>
        ) : null}
      </>
    )
  }

  return (
    <Layout pageTitle={`Remove Service Access`}>
      <Header>Remove Service Access</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectUser(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectUser ? (
              pageLoading ? (
                <Col className="d-flex justify-content-center align-items-center">
                  <PropagateLoader
                    className="align-self-center"
                    size={20}
                    color={"#9c3"}
                    loading={true}
                  />
                </Col>
              ) : (
                <>
                  <Row className="mb-2">
                    <UserView
                      user={selectUser}
                      mode="v"
                      orgIndex={index}
                      isSuperAdmin={isSuperAdmin}
                      selectParentOrg={name}
                    />
                    <div style={{ width: "100%" }}>
                      <Row className="mb-12">
                        <Col xm={6} sm={6} md={6} className="text-right">
                          Services:
                        </Col>
                        <Col>
                          {["email", "gitlab", "artifactory", "wiki", "servicedesk"].some(k =>
                            _.has(projectInfo, k)
                          ) ? (
                            <Row>
                              {overViewImg(projectInfo, "email")}
                              {overViewImg(projectInfo, "gitlab")}
                              {overViewImg(projectInfo, "artifactory")}
                              {overViewImg(projectInfo, "servicedesk")}
                              {overViewImg(projectInfo, "wiki")}
                            </Row>
                          ) : (
                            "None"
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <Row className="justify-content-center mb-2">
                    <Col
                      xs={{ size: 12 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Form method="POST" onSubmit={handleSubmit} style={{ width: 244 }}>
                        <h5>Remove Service Access</h5>
                        {removeServices.length ? (
                          <>
                            <FormGroup>
                              <Input
                                type="select"
                                name="removeService"
                                id="removeService"
                                value={removeService}
                                onChange={event => setRemoveService(event.target.value)}
                              >
                                {removeServices.map(s => (
                                  <option value={s}>{ServiceDisplayName(s)}</option>
                                ))}
                              </Input>
                            </FormGroup>
                            <Button
                              type="submit"
                              onClick={handleSubmit}
                              disabled={
                                formInvalid || savingData || (!index && !parentOrganization)
                              }
                              className="mr-2"
                              color={"primary"}
                            >
                              {savingData ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                >
                                  <span className="sr-only">Saving...</span>
                                </span>
                              ) : (
                                `Update Service Access`
                              )}
                            </Button>
                          </>
                        ) : (
                          "No available service to select."
                        )}
                        {!formInvalid ? (
                          <div className="mt-3">
                            <h5>Pending changes</h5>
                            <ul>
                              {changes.map((p, index) => (
                                <li key={index}>{p}</li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </>
              )
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default UpdateUserServiceStatus
