import React, { useEffect, useState } from "react"
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"
import { useUserOrgs } from "../../hooks/use-user"
import { Loading } from "../loading"
import Layout from "../layout/layout"
import Content from "../content/content"
import Header from "../header/header"
import { fetch_get } from "../../utils/fetch"

const PermissionDeniedPage = (
  <Layout pageTitle="Permission Denied">
    <Header contentPage={true} className="contentPage">
      <h1 className="display-4 font-weight-bold">Permission Denied</h1>
    </Header>
    <Content>
      <p>You have no permission to access this page.</p>
    </Content>
  </Layout>
)

const AccessPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [pageLoading, setPageLoading] = useState(true)
  const [isAccess, setIsAccess] = useState(false)

  useEffect(() => {
    if (!loading) {
      getAccessTokenSilently().then(accessToken =>
        fetch_get(
          `self/user/ticket/permissions${isSuperAdmin ? "" : `?index=${index}`}`,
          accessToken
        ).then(response => {
          setIsAccess(response.find(r => r.display_name === rest.title) || false)
          setPageLoading(false)
        })
      )
    }
  }, [loading])

  return pageLoading ? <Loading /> : isAccess ? <Component {...rest} /> : PermissionDeniedPage
}

export const AccessRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(AccessPage)
  return <PrivateComponent {...args} />
}
