import React, { useState, useEffect } from "react"
import { useUserOrgs } from "../../../hooks/use-user"
import { fetch_get } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useSearch } from "../../../hooks/use-search"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CircularProgress from "@material-ui/core/CircularProgress"
import { MdClose } from "react-icons/md"

const Index = props => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  const [loadingData, setLoadingData] = useState(false)
  const [searchSubGroups, setSearchSubGroups] = useState([])
  const [errorMeaasge, setErrorMeaasge] = useState("")
  const [childKey, setChildKey] = useState("")

  function getParentOrganization() {
    return props.parentOrganization
  }

  function getAdditionalUrlParams() {
    var urlparams = ""
    if (isSuperAdmin) urlparams = `?parentOrganization=${getParentOrganization()}`
    return urlparams
  }

  useEffect(() => {
    if (props.projectSlug) {
      setChildKey(props.projectSlug)
      filterSubGroups("")
    }
  }, [props.projectSlug]) // eslint-disable-line react-hooks/exhaustive-deps

  const callback = v => {
    filterSubGroups(v?.target?.value || "")
  }

  const { callback: handleSearchValue } = useSearch(callback)

  const filterSubGroups = searchString => {
    var urlparams = getAdditionalUrlParams()
    var urlparamsAmp = urlparams.replace("?", "&")
    setErrorMeaasge("")
    if (searchString) {
      let url = index
        ? `projects/${props.projectSlug}/git/subgroups?index=${index}&path=${searchString}${urlparamsAmp}`
        : `projects/${props.projectSlug}/git/subgroups?path=${searchString}${urlparamsAmp}`
      setLoadingData(true)
      getAccessTokenSilently()
        .then(accessToken => fetch_get(url, accessToken))
        .then(response => {
          setSearchSubGroups(response["data"] || [])
          setLoadingData(false)
        })
        .catch(error => {
          console.log(`Error while getting list of subgroups: ${error}`)
          Sentry.captureException(error)
          setSearchSubGroups([])
          props.selectData(null)
          setErrorMeaasge(error? error.message: "An error occurred while getting the list of subgroups.")
          setLoadingData(false)
        })
    } else {
      setSearchSubGroups([])
      props.selectData(null)
      setLoadingData(false)
    }
  }

  return (
    <div key={childKey}>
      <Autocomplete
        id="ProjectSearch"
        fullWidth
        noOptionsText={
          loadingData ? "Loading..." : errorMeaasge ? errorMeaasge : "No subgroup to select."
        }
        options={searchSubGroups}
        filterOptions={option => option}
        loading={loadingData}
        closeIcon={
          <MdClose
            onClick={() => {
              filterSubGroups("")
            }}
          />
        }
        onChange={(event, value, reason) => {
          if (reason === "clear") {
            props.selectData(null)
          } else if (reason === "select-option") {
            props.selectData(value)
          }
        }}
        getOptionLabel={option => option.relativePath}
        renderOption={option => {
          return (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                style={{ width: "100%", padding: "6px 16px" }}
                onClick={() => {
                  props.selectData(option)
                  filterSubGroups(option.relativePath)
                }}
              >
                {option.relativePath}
              </div>
            </>
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Search for a group..."
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              onChange: event => handleSearchValue(event),
              endAdornment: (
                <React.Fragment>
                  {loadingData ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

export default Index
