import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import ErrorPage from "./404"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import ProtectedRoute from "../components/protected-route"
import Summary from "./support/summary"
import CreateUser from "./support/users/create-user"
import CreateUserBulk from "./support/users/create-user-bulk"
import ModifyUser from "./support/users/modify-user"
import UpdateUserStatus from "./support/users/update-user-status"
import EnableUserServices from "./support/users/enable-user-service"
import DisableUserServices from "./support/users/disable-user-service"
import RemoveUserServices from "./support/users/remove-user-service"
import ResetPassword from "./support/users/reset-password"
import ResetMfa from "./support/users/reset-mfa"
import CreateProject from "./support/projects/create-project"
import ArchivePublicProject from "./support/projects/archive-public-project"
import ChangeProjectStatus from "./support/projects/change-project-status"
import ExportProject from "./support/projects/export-project"
import DeleteProject from "./support/projects/delete-project"
import AddUsersToProjectRole from "./support/projects/add-users-to-project-role"
import RemoveUsersToProjectRole from "./support/projects/remove-users-to-project-role"
import RequestProjectDeduplication from "./support/projects/request-project-deduplication"
import CreateProjectGroup from "./support/gitlab/create-a-project-group"
import DeleteProjectGroup from "./support/gitlab/delete-a-project-group"
import CreateProjectSubGroup from "./support/gitlab/create-a-project-subgroup"
import DeleteProjectSubGroup from "./support/gitlab/delete-a-project-subgroup"
import CreateGitLabRepo from "./support/gitlab/create-a-repository"
import CreateFork from "./support/gitlab/create-a-fork"
import CreateMirror from "./support/gitlab/create-a-mirror"
import DeleteGitLabRepo from "./support/gitlab/delete-a-repository"
import CleanRepository from "./support/gitlab/clean-a-repository"
import EnableDCO from "./support/gitlab/enable-dco-for-repository"
import DisableDCO from "./support/gitlab/disable-dco-for-repository"
import CreateArtifactoryRepo from "./support/artifactory/create-artifactory-repository"
import UploadArtifact from "./support/artifactory/upload-an-artifact"
import DeleteArtifactoryRepo from "./support/artifactory/delete-artifactory-repository"
import CreateWiki from "./support/wiki/create-a-wiki"
import DeleteWiki from "./support/wiki/delete-a-wiki"
import ReportProblem from "./support/other/report_a_service_outage_or_disruption"
import Inquiry from "./support/other/inquiry"
import FeatureRequest from "./support/other/feature_request"
import ServiceRequest from "./support/other/service_request"
import Informational from "./support/other/informational"
import RequestUserAccessForIrc from "./support/irc/request-user-access-to-irc"
import RemoveUserAccessForIrc from "./support/irc/remove-user-access-for-irc"
import CreateRepoGithub from "./support/github/create-a-repository"
import RemoveRepoGithub from "./support/github/remove-a-repository"
import AddUserToRepoGithub from "./support/github/add-a-user-to-repository"
import RemoveUserFromRepoGithub from "./support/github/remove-a-user-from-repository"
import CreateForkGithub from "./support/github/create-a-fork"
import RequestEmailArchive from "./support/email/request_email_archive"
import RequestEmailMetrics from "./support/email/request_email_metrics"
import { AccessRoute } from "../components/support/access-route"
import { Error } from "../components/error"
import { fetch_get } from "../utils/fetch"

const Support = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <ProtectedRoute path="/inner-support" component={Summary} />
        <AccessRoute path="/inner-support/create-user" component={CreateUser} title="Create user" />
        <AccessRoute
          path="/inner-support/create-user-bulk"
          component={CreateUserBulk}
          title="Request to create multiple user accounts"
        />
        <AccessRoute
          path="/inner-support/reset-password"
          component={ResetPassword}
          title="Request password reset"
        />
        {/* <AccessRoute
          path="/inner-support/reset-mfa"
          component={ResetMfa}
          title="Request MFA Reset"
        /> */}
        <AccessRoute path="/inner-support/modify-user" component={ModifyUser} title="Modify user" />
        <AccessRoute
          path="/inner-support/enable-user"
          component={UpdateUserStatus}
          title="Enable user"
        />
        <AccessRoute
          path="/inner-support/disable-user"
          component={UpdateUserStatus}
          title="Disable user"
        />
        <AccessRoute
          path="/inner-support/remove-user"
          component={UpdateUserStatus}
          title="Remove user"
        />
        <AccessRoute
          path="/inner-support/enable-user-services"
          component={EnableUserServices}
          title="Enable service access"
        />
        <AccessRoute
          path="/inner-support/disable-user-services"
          component={DisableUserServices}
          title="Disable service access"
        />
        <AccessRoute
          path="/inner-support/remove-user-services"
          component={RemoveUserServices}
          title="Remove service access"
        />
        <AccessRoute
          path="/inner-support/create-project"
          component={CreateProject}
          title="Create project"
        />
        <AccessRoute
          path="/inner-support/archive-public-project"
          component={ArchivePublicProject}
          title="Archive public project"
        />
        <AccessRoute
          path="/inner-support/change-project-status"
          component={ChangeProjectStatus}
          title="Change project status"
        />
        <AccessRoute
          path="/inner-support/export-project"
          component={ExportProject}
          title="Export project"
        />
        <AccessRoute
          path="/inner-support/delete-project"
          component={DeleteProject}
          title="Delete project"
        />
        <AccessRoute
          path="/inner-support/add-users-to-project-role"
          component={AddUsersToProjectRole}
          title="Request project access"
        />
        <AccessRoute
          path="/inner-support/remove-users-to-project-role"
          component={RemoveUsersToProjectRole}
          title="Remove project access"
        />
        <AccessRoute
          path="/inner-support/request-project-deduplication"
          component={RequestProjectDeduplication}
          title="Request project deduplication"
        />
        <AccessRoute
          path="/inner-support/create-a-project-group"
          component={CreateProjectGroup}
          title="Create a project group"
        />
        <AccessRoute
          path="/inner-support/delete-a-project-group"
          component={DeleteProjectGroup}
          title="Delete a project group"
        />
        <AccessRoute
          path="/inner-support/create-a-project-subgroup"
          component={CreateProjectSubGroup}
          title="Create a project subgroup"
        />
        <AccessRoute
          path="/inner-support/delete-a-project-subgroup"
          component={DeleteProjectSubGroup}
          title="Delete a project subgroup"
        />
        <AccessRoute
          path="/inner-support/create-a-repository"
          component={CreateGitLabRepo}
          title="Create a repository"
        />
        <AccessRoute
          path="/inner-support/create-a-fork"
          component={CreateFork}
          title="Create a fork"
        />
        <AccessRoute
          path="/inner-support/create-a-mirror"
          component={CreateMirror}
          title="Create a mirror"
        />
        <AccessRoute
          path="/inner-support/enable-dco-for-repository"
          component={EnableDCO}
          title="Enable DCO for repository"
        />
        <AccessRoute
          path="/inner-support/disable-dco-for-repository"
          component={DisableDCO}
          title="Disable DCO for repository"
        />
        <AccessRoute
          path="/inner-support/clean-a-repository"
          component={CleanRepository}
          title="Clean a repository"
        />
        <AccessRoute
          path="/inner-support/delete-a-repository"
          component={DeleteGitLabRepo}
          title="Delete a repository"
        />
        <AccessRoute
          path="/inner-support/create-artifactory-repository"
          component={CreateArtifactoryRepo}
          title="Create artifacts repository"
        />
        <AccessRoute
          path="/inner-support/upload-an-artifact"
          component={UploadArtifact}
          title="Upload an artifact"
        />
        <AccessRoute
          path="/inner-support/delete-artifactory-repository"
          component={DeleteArtifactoryRepo}
          title="Delete artifacts repository"
        />
        <AccessRoute
          path="/inner-support/create-a-wiki"
          component={CreateWiki}
          title="Create a wiki"
        />
        <AccessRoute
          path="/inner-support/delete-a-wiki"
          component={DeleteWiki}
          title="Delete a wiki"
        />
        <AccessRoute
          path="/inner-support/request-user-access-to-irc"
          component={RequestUserAccessForIrc}
          title="Request user access to IRC"
        />
        <AccessRoute
          path="/inner-support/remove-user-access-for-irc"
          component={RemoveUserAccessForIrc}
          title="Remove user access for IRC"
        />
        <AccessRoute
          path="/inner-support/create-a-repository-github"
          component={CreateRepoGithub}
          title="Create a repository (GitHub)"
        />
        <AccessRoute
          path="/inner-support/remove-a-repository-github"
          component={RemoveRepoGithub}
          title="Remove a repository (GitHub)"
        />
        <AccessRoute
          path="/inner-support/create-a-fork-github"
          component={CreateForkGithub}
          title="Create a Fork (GitHub)"
        />
        <AccessRoute
          path="/inner-support/add-a-user-to-repository-github"
          component={AddUserToRepoGithub}
          title="Add a user to repository (GitHub)"
        />
        <AccessRoute
          path="/inner-support/remove-a-user-from-repository-github"
          component={RemoveUserFromRepoGithub}
          title="Remove a user from repository (GitHub)"
        />
        <AccessRoute
          path="/inner-support/request_email_archive"
          component={RequestEmailArchive}
          title="Request Email Archive"
        />
        <AccessRoute
          path="/inner-support/request_email_metrics"
          component={RequestEmailMetrics}
          title="Request Email Metrics"
        />
        <AccessRoute
          path="/inner-support/report_a_service_outage_or_disruption"
          component={ReportProblem}
          title="Report a Service Outage or Disruption"
        />
        <AccessRoute path="/inner-support/inquiry" component={Inquiry} title="Inquiry" />
        <AccessRoute
          path="/inner-support/feature_request"
          component={FeatureRequest}
          title="Feature Request"
        />
        <AccessRoute
          path="/inner-support/service_request"
          component={ServiceRequest}
          title="Service Request"
        />
        <AccessRoute
          path="/inner-support/informational"
          component={Informational}
          title="Informational"
        />
        <ErrorPage default />
      </Router>
    </>
  )
}

export default Support
